// 验证用户名
export function dottom(username) {
    const myReg = /^([1-9]+[0-9]*)|((([1-9]+[0-9]*)|[0]).[0-9]{1,2})|[0]$/;
    return myReg.test(username);
}

// 验证用户名
export function userNameCheck(username) {
    const myReg = /^(?=.*[a-zA-Z])\w{4,20}$/i;
    return myReg.test(username);
}

// 验证姓名(中英文字符 _ · . 数字 1-30位)
export function nameCheck(name) {
    const myReg = /^[a-zA-Z0-9_\u4e00-\u9fa5·.]{1,30}$/i;
    return myReg.test(name);
}

// 验证手机号码
export function mobileCheck(phone) {
    const myReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    return myReg.test(phone);
}

// 验证身份证
export function sfzNumCheck(sfzNum) {
    const myReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    return myReg.test(sfzNum);
}

// 港澳通行证
export function GANumCheck(sfzNum) {
    const myReg = /^((\d{7})|([C|W|H|M]\d{8}))$/;
    return myReg.test(sfzNum);
}

// 台胞证
export function TBNumCheck(sfzNum) {
    const myReg = /^((\d{8})|(\d{10}[（][A-Z][）]))$/;
    return myReg.test(sfzNum);
}

// 国内护照
export function PPNumCheck(sfzNum) {
    const myReg = /^(([D|S|P]\d{7})|([G|E]\d{8}))$/;
    return myReg.test(sfzNum);
}

// 验证邮箱
export function mailCheck(mail) {
    const myReg = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return myReg.test(mail);
}

// 密码
export function checkStrong(sValue) {
    let modes = 0;
    if (sValue.length < 6) return modes;
    if (/\d/.test(sValue)) modes++;
    if (/[a-z]/.test(sValue)) modes++;
    if (/[A-Z]/.test(sValue)) modes++;
    if (/\W/.test(sValue)) modes++;
    switch (modes) {
        case 1:
            return 1;
            break;
        case 2:
            return 2;
            break;
        case 3:
        case 4:
            return sValue.length < 4 ? 2 : 3;
            break;
    }
    return modes;
}

export function getDate(fmt, dt = new Date()) {
    let o = {
        "M+": dt.getMonth() + 1, //月份
        "d+": dt.getDate(), //日
        "h+": dt.getHours(), //小时
        "m+": dt.getMinutes(), //分
        "s+": dt.getSeconds(), //秒
        "q+": Math.floor((dt.getMonth() + 3) / 3), //季度
        S: dt.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (dt.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
}

// 获取两个日期相差的好描述
export function timeSpan(d1, d2) {
    let dateBegin = new Date(d1.replace(/-/g, "/")); //将-转化为/，使用new Date
    let dateEnd = new Date(d2.replace(/-/g, "/")); //将-转化为/，使用new Date

    let dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数

    return Math.round(dateDiff / 1000);
}
