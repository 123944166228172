<template>
    <div class="box_content">
        <div class="ConDivContent_bg">
            <div class="container">
                <div class="title">账号登录</div>
                <el-form
                        :model="loginForm"
                        :rules="loginFormRules"
                        ref="loginFormRef"
                        class="demo-ruleForm"
                >
                    <el-form-item prop="username">
                        <img
                                class="img_l"
                                src="../../../assets/images/username.png"
                                alt=""
                        />
                        <el-input
                                v-model="loginForm.username"
                                placeholder="身份证/港澳通行证/台胞证/国内护照号码"
                                maxlength="20"
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <img
                                class="img_l"
                                src="../../../assets/images/password.png"
                                alt=""
                        />
                        <el-input
                                v-model="loginForm.password"
                                :type="isShowPwd ? 'password' : 'text'"
                                maxlength="20"
                                placeholder="密码"
                        ></el-input>
                        <img
                                @click="changePwdStatus()"
                                v-if="!isShowPwd"
                                class="img_r"
                                src="../../../assets/images/eye01.png"
                                alt=""
                        />
                        <img
                                @click="changePwdStatus()"
                                v-if="isShowPwd"
                                class="img_r"
                                src="../../../assets/images/eye02.png"
                                alt=""
                        />
                    </el-form-item>
                    <div class="agree">
                        <el-checkbox v-model="agreement" @change="onChange">我已阅读并同意</el-checkbox>
                        <span class="txt" @click="toService">《用户服务协议》</span>
                    </div>
                    <el-form-item>
                        <el-button :disabled="!agreement" @click="submitForm()">登 录</el-button>
                    </el-form-item>
                    <div class="box">
                        <div class="fl" @click="forgetPwd()">忘记账号/密码？</div>
                        <div class="fr" @click="register()">新用户注册</div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="content">
            <div class="floatL">
                <h3>考试信息</h3>
                <ul>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/2QwznHP6gRZhEzi7sQ4kfg"
                        >THUSSAT丨2023年4月-2024年3月全年考试计划</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/P_OX2cGkUDHqmuqaHqUkhg"
                        >THUSSAT丨学科能力综合测试(TACA)</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/K46y9jUyWSKtoe3GJZ8Kcw"
                        >THUSSAT丨国际数理综合能力测试(TIST)</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/Ip7sGW54-Fj1nYMyPBL_7w"
                        >THUSSAT丨诊断性测试(TDA)</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/0M_4naRNED88poq1bh_h8g"
                        >THUSSAT丨声明</a>
                    </li>
                    <!--<li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/vvO3x4j9NImOP5HqslZWxQ"
                        >2021年8月THUSSAT学科能力综合测试(TACA)开放报名</a
                        >
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/lqXh9RUnets6mzjumJNz_A"
                        >THUSSAT | 2021.4-2022.3考试计划</a
                        >
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/yo4WlCLtlBBgrTvUTJ2UvQ"
                        >THUSSAT | 2022.4-2023.3考试计划</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/Seak0aBGtjoF37O7m92Mkg"
                        >THUSSAT学科能力综合测试(TACA)介绍</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/g3XgDNqNoZNq9iVg_W1ziw"
                        >THUSSAT诊断性测试(TDA)介绍</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/vEuoutJD6sWVMLYn3cEaIg"
                        >THUSSAT基础性测试(TGA)介绍</a>
                    </li>
                    <li>
                        <a target="_blank"
                           href="https://mp.weixin.qq.com/s/IJpJhjG55sN4TzPNykEY9A"
                        >THUSSAT声明</a>
                    </li>-->
                </ul>

                <div class="ContentLReg"></div>
                <div class="ContentLReg">
                    您还不是本系统用户？<span @click="register()">马上注册</span>
                </div>
                <div class="img_box">
                    <img src="../../../assets/images/fwh.png"/>
                    <img src="../../../assets/images/dyh.png"/>
                </div>
            </div>
            <div class="floatR">
                <ul>
                    <li>
                        <span>全面的知识能力维度</span>
                    </li>
                    <li>
                        <span>多样的数据呈现方式</span>
                    </li>
                    <li>
                        <span>先进的考试组织形态</span>
                    </li>
                </ul>
            </div>
        </div>

        <dialog-box
                :dialogVisibleFu="dialogVisibleFu"
                :dialogFormFu="dialogFormFu"
                @closeDialog="closeDialog"
        ></dialog-box>
    </div>
</template>

<script>
    import {login} from "r/index/login.js";
    import {
        userNameCheck,
        sfzNumCheck,
        GANumCheck,
        TBNumCheck,
        PPNumCheck,
    } from "common/utils.js";
    import DialogBox from "c/index/register/DialogBox";

    export default {
        name: "Login",
        data() {
            return {
                isShowPwd: true, // 控制密码显示隐藏
                loginForm: {
                    username: "",
                    password: "",
                },
                // 表单验证规则对象
                loginFormRules: {
                    // 对用户名进行校验
                    username: [
                        {required: true, message: "请输入身份证/港澳通行证/台胞证/国内护照号码", trigger: "blur"},
                        // {
                        //     validator: function (rule, value, callback) {
                        //         if (userNameCheck(value) === false
                        //             && sfzNumCheck(value) === false
                        //             && GANumCheck(value) === false
                        //             && TBNumCheck(value) === false
                        //             && PPNumCheck(value) === false) {
                        //             callback(new Error("格式错误"));
                        //         } else {
                        //             callback();
                        //         }
                        //     },
                        //     trigger: "blur",
                        // },
                    ],
                    // 对密码进行校验
                    password: [
                        {required: true, message: "请输入密码", trigger: "blur"},
                        {min: 6, max: 20, message: "请输入正确的密码", trigger: "blur"},
                    ],
                },
                dialogFormFu: {
                    title: "",
                    message: "",
                },
                agreement: false,
                dialogVisibleFu: false,
            };
        },
        created() {
            this.$emit('getStatus', false)
            let agreeLogin = window.localStorage.getItem('agree-login');
            if (agreeLogin && agreeLogin === 'true') {
                this.agreement = true;
            } else {
                this.agreement = false;
            }
        },
        methods: {
            onChange(val) {
                window.localStorage.setItem('agree-login', val)
            },
            // 打开用户服务协议
            toService() {
                let href = window.location.href.replace('login', 'service')
                window.open(href, "_blank");
            },
            // 忘记账号/密码
            forgetPwd() {
                window.localStorage.setItem('f_forgetpwd', 0)
                this.$router.replace({name: "foundback"});
            },
            // 子组件触发，关闭弹框
            closeDialog(value, type) {
                this.dialogVisibleFu = value;
                if (type) {
                    this.$router.replace({name: "StepOne"});
                }
            },
            // 去注册
            register() {
                this.dialogFormFu = {
                    title: "提示",
                    message: 1,
                };
                this.dialogVisibleFu = true;
                // this.$router.replace({ name: "StepOne" });
            },
            // 改变密码显示状态
            changePwdStatus() {
                this.isShowPwd = !this.isShowPwd;
            },
            // 表单验证
            submitForm() {
                if (
                    this.loginForm.username.slice(17, 18) == "x" ||
                    this.loginForm.username.slice(17, 18) == "X"
                ) {
                    this.loginForm.username = this.loginForm.username.slice(0, 17) + "X";
                }
                this.$refs.loginFormRef.validate(async (valid) => {
                    // 验证用户名和密码是否符合规定
                    if (!valid) return

                    // 发送请求的操作
                    let {data: res} = await login(this.loginForm);
                    console.log(res, "返回用户注册数据");
                    // 根据返回的状态码做想关的判断
                    if (res.code !== 200) {
                        return this.$message.error(res.message);
                    }

                    //判断用户注册是否填写信息完整
                    let applicationstatus = parseInt(res.applicationstatus);
                    let can_skip = parseInt(res.can_skip);
                    window.localStorage.setItem("applicationstatus", res.applicationstatus);
                    window.localStorage.setItem("can_skip", res.can_skip);
                    window.localStorage.setItem("is_check_id_card", res.is_check_id_card);
                    if (!can_skip) {
                        if (applicationstatus == 0) {
                            window.localStorage.setItem("sfzNum", res.sfzNum);
                            window.localStorage.setItem("name", res.name);
                            window.localStorage.setItem("username", res.username);
                            window.localStorage.setItem("email", res.email);
                            window.localStorage.setItem("phone", res.phone);
                            window.localStorage.setItem("school_name", res.school.name);
                            window.localStorage.setItem("school_province", res.school.province_name);
                            window.localStorage.setItem("school_city", res.school.city_name);
                            window.localStorage.setItem("school_id", res.school.id);
                            window.localStorage.setItem("school_province_id", res.school.province_id);
                            window.localStorage.setItem("school_city_id", res.school.city_id);
                            await this.$router.replace({
                                name: "StepOne",
                                query: {applicationstatus: "0", data: res},
                            });
                            return;
                        }
                    }
                    this.$store.state.token = res.token;
                    window.localStorage.setItem("index-token", res.token);
                    window.localStorage.setItem("student_id", res.student_id);
                    window.localStorage.setItem("sfzNum", res.sfzNum);
                    window.localStorage.setItem("username", res.username);
                    window.localStorage.setItem("name", res.name);
                    await this.$router.replace({name: "examlist"});

                    // //判断用户注册是否填写信息完整
                    // let applicationstatus = parseInt(res.applicationstatus);
                    // let can_skip = parseInt(res.can_skip);
                    // window.localStorage.setItem("applicationstatus", res.applicationstatus);
                    // window.localStorage.setItem("can_skip", res.can_skip);
                    // window.localStorage.setItem("is_check_id_card", res.is_check_id_card);
                    // console.log(!can_skip)
                    // if (!can_skip) {
                    //     if (applicationstatus == 0) {
                    //         window.localStorage.setItem("sfzNum", res.sfzNum);
                    //         this.$router.replace({
                    //             name: "StepOne",
                    //             query: {applicationstatus: "0", data: res},
                    //         });
                    //         return;
                    //     } else if (applicationstatus == 3 || applicationstatus == 4 || applicationstatus == 5 || applicationstatus == 6) {
                    //         window.localStorage.setItem("index-token", res.token);
                    //         this.$store.state.token = res.token;
                    //         window.localStorage.setItem("student_id", res.student_id);
                    //         window.localStorage.setItem("sfzNum", res.sfzNum);
                    //         window.localStorage.setItem("username", res.username);
                    //         if (res.is_check_id_card != 1) {
                    //             this.$router.replace({name: "StepTwo"});
                    //         } else {
                    //             this.$router.replace({name: "StepThree"});
                    //         }
                    //         return;
                    //     }
                    // }
                    // this.$store.state.token = res.token;
                    // window.localStorage.setItem("index-token", res.token);
                    // window.localStorage.setItem("student_id", res.student_id);
                    // window.localStorage.setItem("sfzNum", res.sfzNum);
                    // window.localStorage.setItem("username", res.username);
                    // await this.$router.replace({name: "examlist"});
                });
            },
        },
        components: {
            DialogBox,
        },
    };
</script>

<style scoped lang="scss">
    // 内容区域
    .box_content {
        .ConDivContent_bg {
            height: 450px;
            background: url("../../../assets/images/bgpicture.png") no-repeat center center;
            background-size: 100% 100%;
            position: relative;
        }

        @media screen and (min-width: 1601px) {
            .ConDivContent_bg {
                height: 650px;
                max-height: 650px;
            }
        }

        .content {
            max-width: 1150px;
            margin: 40px auto;
            padding: 0 50px;
            overflow: auto;

            .floatL {
                float: left;
                width: 670px;

                h3 {
                    font-size: 20px;
                    color: #4c4c4c;
                    font-weight: inherit;
                    padding-bottom: 15px;
                    margin-bottom: 23px;
                    border-bottom: 4px solid #e0823d;
                }

                ul {
                    li {
                        height: 40px;
                        line-height: 40px;
                        border-bottom: 1px solid #ddd;

                        a {
                            display: inline-block;
                            width: 100%;
                            font-size: 16px;
                            color: #34538b;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .ContentLReg {
                    height: 60px;
                    color: #333333;
                    font-size: 14px;

                    span {
                        cursor: pointer;
                        color: #563279;
                        font-weight: bold;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .img_box {
                    width: 100%;
                    height: 156px;
                    text-align: center;

                    img {
                        &:nth-child(1) {
                            margin-right: 40px;
                        }
                    }
                }
            }

            .floatR {
                float: right;
                width: 374px;

                ul {
                    margin-top: 36px;
                    margin-left: 87px;

                    li {
                        font-size: 16px;
                        width: 100%;
                        height: 45px;
                        line-height: 45px;
                        padding-left: 70px;
                        margin-bottom: 35px;

                        &:nth-child(1) {
                            background: url("../../../assets/images/weidu.png") no-repeat 0px center;
                        }

                        &:nth-child(2) {
                            background: url("../../../assets/images/fangshi.png") no-repeat 0px center;
                        }

                        &:nth-child(3) {
                            background: url("../../../assets/images/xingtai.png") no-repeat 0px center;
                        }
                    }
                }
            }
        }
    }

    .container {
        width: 346px;
        height: 383px;
        position: absolute;
        top: 50%;
        right: 18%;
        // left: 10%;
        transform: translate(0, -50%);
        padding: 20px;
        margin-top: 0;
        background: url("../../../assets/images/loginbgp.png") no-repeat 0 center;
        overflow: hidden;

        .title {
            color: #4c4c4c;
            font-size: 20px;
            font-weight: inherit;
            /*margin: 19px 0 25px 0;*/
            margin: 15px 0 20px 0;
        }

        .el-form {
            .el-form-item {
                position: relative;
                /*margin-bottom: 30px;*/
                &:last-child {
                    margin: 0;
                }

                img.img_l {
                    position: absolute;
                    left: 25px;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1000;
                }

                img.img_r {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translate(0, -50%);
                    z-index: 1000;
                }

                ::v-deep .el-input__inner {
                    height: 50px;
                    line-height: 50px;
                    padding: 0 10px 0 50px;
                    border-radius: 0;
                    color: #4d4d4d;
                    border: 2px solid #d9d9d9;

                    &:focus {
                        border-color: #563279;
                    }
                }

                ::v-deep .el-form-item__error {
                    color: #e0823d;
                }

                .el-button {
                    width: 300px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    background: #563279;
                    color: #fff;
                    font-size: 16px;
                    border-radius: 6px;
                    padding: 0;
                    margin: 30px 0 0 0;

                    &.is-disabled,
                    &.is-disabled:focus,
                    &.is-disabled:hover {
                        color: rgba(225, 225, 225, .6);
                        cursor: not-allowed;
                        background-image: none;
                        background-color: rgba(86, 50, 121, .6);
                        border-color: #EBEEF5;
                    }
                }
            }

            .agree {
                ::v-deep .el-checkbox {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            border-color: #DCDFE6;
                        }

                        .el-checkbox__inner:hover {
                            border-color: #563279;
                        }
                    }

                    &.is-checked {
                        .el-checkbox__input {
                            .el-checkbox__inner {
                                background-color: #563279;
                                border-color: #563279;
                            }
                        }

                        .el-checkbox__label {
                            color: #563279;
                        }
                    }
                }

                .txt {
                    margin-left: 10px;
                    color: #563279;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }

            .box {
                height: 18px;
                display: block;

                .fl {
                    float: left;
                    height: 18px;
                    line-height: 18px;
                    color: #999999;
                    margin-top: -10px;
                    cursor: pointer;

                    &:hover {
                        color: #563279;
                        text-decoration: underline;
                    }
                }

                .fr {
                    float: right;
                    height: 18px;
                    line-height: 18px;
                    color: #999999;
                    margin-top: -10px;
                    cursor: pointer;

                    &:hover {
                        color: #563279;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1601px) {
        .container {
            right: 18%;
        }
    }

    @media screen and (max-width: 1501px) {
        .container {
            right: 16%;
        }
    }

    @media screen and (max-width: 1401px) {
        .container {
            right: 14%;
        }
    }
</style>
