<template>
  <el-dialog
    :title="dialogFormFu.title"
    :visible.sync="dialogVisible"
    :width="dialogFormFu.message == 1?'500px':'400px'"
    top="0"
    :before-close="cancelBtn"
    :close-on-click-modal="false"
  >
    <!-- 去注册区域 -->
    <div class="info_2" v-if="dialogFormFu.message == 1">
      如参加过THUSSAT序列考试,比如TDA诊断性测试或其他测试，可使用身份证及初始密码（身份证后六位）直接登录。为确保信息安全，请在登录后尽快修改密码。
    </div>
    <!-- 提示弹框 -->
    <div class="info_3" v-else v-html="dialogFormFu.message"></div>


    <span slot="footer" class="dialog-footer" v-if="dialogFormFu.message == 1">
      <!-- 去注册区域 -->
      <el-button class="cancel" @click="cancelBtn()">登 录</el-button>
      <el-button class="confirm" @click="confirm()">注 册</el-button>
    </span>
    <!-- 提示弹框 -->
    <span slot="footer" class="dialog-footer" v-else>
      <el-button class="confirm" @click="confirm()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "DialogBox",
  props: {
    dialogFormFu: Object,
    dialogVisibleFu: Boolean,
  },
  data() {
    return {
      dialogVisible: this.dialogVisibleFu,
    };
  },
  methods: {
    confirm() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible, true);
    },
    // 取消,关闭弹框，修改父组件的值
    cancelBtn() {
      this.dialogVisible = false;
      this.$emit("closeDialog", this.dialogVisible);
    },
  },
  watch: {
    dialogVisibleFu() {
      this.dialogVisible = this.dialogVisibleFu;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/dialog";
::v-deep .el-dialog__body {
  padding: 0 48px 30px 48px !important;
}
::v-deep .el-dialog__footer {
  margin: 0;
  padding: 10px 0 30px !important;
  text-align: center;
}
</style>
